







































import { Validation } from "@vuelidate/core";
import InputMask from "@/components/UI/InputMask.vue";
import { defineComponent, PropType } from "vue";

export default defineComponent({
  name: "TimePicker",
  components: { InputMask },
  props: {
    label: {
      type: String as PropType<string>,
      default: ""
    },
    value: {
      type: String as PropType<string>,
      required: true
    },
    meta: {
      type: Object as PropType<Validation>,
      default: () => ({} as Validation)
    },
    error: {
      type: String as PropType<string>,
      default: ""
    },
    allowedMinutes: {
      type: [Function, Array] as PropType<(val: number) => boolean | number[]>,
      default: () => []
    }
  },
  data() {
    return {
      showTimepicker: false
    };
  }
});
