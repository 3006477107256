import { computed, Ref } from "vue";
import { PersonType } from "@/models/person/Person.interface";
import { PersonItemInterface } from "@/models/person/PersonItem.interface";

export function useTaxTypes(person: Ref<PersonItemInterface>) {
  const taxesTypes = computed<{ text: string; value: PersonType }[]>(() => {
    return [
      { text: "Юр. лицо", value: PersonType.COMPANY },
      { text: "ИП", value: PersonType.ENTREPRENEUR },
      { text: "Физическое лицо", value: PersonType.INDIVIDUAL }
    ];
  });

  const getTaxType = (value: PersonType): string => {
    const taxType = taxesTypes.value.find(taxType => taxType.value === value);

    return (taxType && taxType.text) || "";
  };

  return {
    taxesTypes,
    getTaxType
  };
}
