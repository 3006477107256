
























































































































import BaseInput from "@/components/UI/BaseInput.vue";
import { PersonType } from "@/models/person/Person.interface";
import { OrganizationTypeEnum } from "@/models/autocomplete/OrganizationType.enum";
import { defineComponent, onMounted, PropType, toRef } from "vue";
import { useTaxTypes } from "@/components/personItem/useTaxTypes";
import { usePersonValidation } from "@/components/personItem/usePersonValidation";
import { PersonItemInterface } from "@/models/person/PersonItem.interface";
import PersonService from "@/models/person/Person.service";
import OrderService from "@/models/order/Order.service";
import { usePersonInnFieldViewConfig } from "@/components/personItem/usePersonInnFieldViewConfig";
import { OrganizationInterface } from "@/models/store/Organization.interface";
import CountrySelect from "@/components/order/city/CountrySelect.vue";
import { CountryInterface } from "@/models/global/CountryInterface";

export default defineComponent({
  name: "PersonItem",
  components: {
    CountrySelect,
    BaseInput
  },
  props: {
    person: {
      type: Object as PropType<PersonItemInterface>,
      required: true
    },
    type: {
      type: [String, Number] as PropType<OrganizationTypeEnum>,
      default: OrganizationTypeEnum.Customer
    }
  },
  setup(props) {
    const _organizationType = toRef(props, "type");
    const _person = toRef(props, "person");
    const isDefaultPlaceOwner = false;

    const { v$ } = usePersonValidation(_person, _organizationType.value);
    const { taxesTypes, getTaxType } = useTaxTypes(_person);
    const {
      returnLabelForInn,
      returnMaskForInn
    } = usePersonInnFieldViewConfig();

    const setInn = async (value: string | PersonItemInterface) => {
      if (typeof value === "string") {
        _person.value.inn = value;
        if (_person.value.countryCode === "RUS") {
          await PersonService.setPersonInn(_person.value, value);
        }
        return;
      }

      await PersonService.updatePerson(
        _person.value,
        value,
        _organizationType.value
      );
    };

    const setCountryCode = async (countryCode: CountryInterface["code"]) => {
      await PersonService.setPersonCountryCode(_person.value, countryCode);
    };

    const changePersonType = (type: PersonType) => {
      PersonService.changePersonType(_person.value, type);
    };
    const orgFilter = (org: OrganizationInterface, query: string): boolean => {
      return (
        (!query?.trim() ||
          org.inn?.includes(query.toLowerCase().trim()) ||
          org.name?.toLowerCase().includes(query.toLowerCase().trim()) ||
          org.lastName?.toLowerCase().includes(query.toLowerCase().trim()) ||
          org.firstName?.toLowerCase().includes(query.toLowerCase().trim()) ||
          org.middleName?.toLowerCase().includes(query.toLowerCase().trim())) ??
        false
      );
    };

    onMounted(() => {
      if (
        ((_person.value.type === PersonType.ENTREPRENEUR ||
          _person.value.type === PersonType.COMPANY) &&
          !!_person.value.inn) ||
        (_person.value.type === PersonType.INDIVIDUAL &&
          !!_person.value.lastName)
      )
        return;

      OrderService.getDefaultPlaceOwner().then(res => {
        if (!res.isSuccess) return;

        if (res.entity) {
          PersonService.updatePerson(
            _person.value,
            res.entity as PersonItemInterface,
            _organizationType.value
          );
        }
      });
    });

    return {
      v$,
      taxesTypes,
      getTaxType,
      setInn,
      changePersonType,
      returnLabelForInn,
      returnMaskForInn,
      orgFilter,
      isDefaultPlaceOwner,
      setCountryCode
    };
  },
  computed: {
    personListAutocomplete(): OrganizationInterface[] {
      let personListName: "customerList" | "senderList" | "recipientList";

      switch (this.type) {
        case OrganizationTypeEnum.Customer:
          personListName = "customerList";
          break;
        case OrganizationTypeEnum.Sender:
          personListName = "senderList";
          break;
        default:
          personListName = "recipientList";
      }

      const personList: OrganizationInterface[] = this.$store.getters[
        `autocomplete/${personListName}`
      ];

      return personList.filter(organization => {
        return organization.type === this.person.type;
      });
    },
    isValidationDefaultPlaceOwner(): boolean {
      if (this.person) {
        switch (this.person.type) {
          case 0: {
            if (
              !this.person.inn ||
              this.v$.inn.$error ||
              !this.person.name ||
              this.v$.name.$error
            )
              return true;
            break;
          }
          case 1: {
            if (
              !this.person.inn ||
              this.v$.inn.$error ||
              !this.person.lastName ||
              this.v$.lastName.$error ||
              !this.person.firstName ||
              this.v$.firstName.$error ||
              !this.person.middleName ||
              this.v$.middleName.$error
            )
              return true;
            break;
          }
          case 2: {
            if (
              !this.person.lastName ||
              this.v$.lastName.$error ||
              !this.person.firstName ||
              this.v$.firstName.$error ||
              !this.person.middleName ||
              this.v$.middleName.$error
            )
              return true;
            break;
          }
        }
        return false;
      }
      return true;
    }
  },
  methods: {
    toggleDefaultPlaceOwner() {
      if (this.isDefaultPlaceOwner)
        OrderService.postDefaultPlaceOwner(this.person);
    }
  }
});
