import { PersonType } from "@/models/person/Person.interface";

export interface PersonFieldViewConfigInterface {
  label: string;
  mask: string;
}

export function usePersonInnFieldViewConfig() {
  function returnLabelForInn(country: string, personType: PersonType): string {
    if (country === "UZB" && personType === PersonType.ENTREPRENEUR)
      return "ПИНФЛ *";
    if (country === "KAZ") return "БИН *";
    return "ИНН *";
  }

  function returnMaskForInn(country: string, personType: PersonType): string {
    if (
      country === "KAZ" ||
      (country === "RUS" && personType === PersonType.ENTREPRENEUR)
    ) {
      return "000000000000";
    }

    if (country === "RUS" && personType === PersonType.COMPANY) {
      return "0000000000";
    }

    if (country === "UZB" && personType === PersonType.ENTREPRENEUR) {
      return "00000000000000";
    }

    if (country === "UZB" && personType === PersonType.COMPANY) {
      return "000000000";
    }

    return "";
  }

  return {
    returnLabelForInn,
    returnMaskForInn
  };
}
