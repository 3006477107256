








































































































































































































































































































































































































































































































import {
  computed,
  ComputedRef,
  defineComponent,
  inject,
  PropType,
  toRefs
} from "vue";
import { OrderItemInterface } from "@/models/order/OrderItem.interface";
import useVuelidate from "@vuelidate/core";
import {
  helpers,
  minLength,
  required,
  requiredIf
} from "@vuelidate/validators";
import { OrderTypeEnum } from "@/models/order/OrderType.enum";
import useForwarding from "@/components/order/compositions/useForwarding";
import InputMask from "@/components/UI/InputMask.vue";
import convertStringToNumber from "@/utils/convertStringToNumber";
import { add } from "date-fns";
import { OrganizationViewInterface } from "@/models/global/OrganizationView.interface";
import OrganizationGroups from "@/components/order/OrganizationGroups.vue";
import { OrganizationGroupInterface } from "@/models/OrganizationGroup/OrganizationGroup.interface";

export default defineComponent({
  components: { InputMask, OrganizationGroups },
  props: {
    order: {
      type: Object as PropType<OrderItemInterface>,
      required: true
    }
  },
  setup(props) {
    const { order } = toRefs(props);
    const orderType = computed(() => order.value.orderType);
    const activationTime = computed(
      () => order.value.auctionExtendActivationTime
    );
    const {
      organizations,
      organizationGroups,
      genContractsUrl,
      filteredForwardingOrganizations,
      allPartnersSelected,
      isInBlackListWarning,
      search,
      organizationsLoading,
      selectedOrganizationGroups,
      selectedNotPartners,
      noDataMessage,
      selectedPartners,
      selectedNotPartnersTitles,
      selectAllPartners,
      selectOrderType,
      selectExecutorRoleType,
      getDefaultConditionValues,
      onSelectFocus,
      onSelectBlur,
      onSearchInput,
      onOrgSelect,
      onAutocompleteChange,
      onUpdateSelectedGroups,
      onSelectGroup
    } = useForwarding(order);

    const updateOrganizationGroups = (groups: OrganizationGroupInterface[]) => {
      organizationGroups.value = groups;
    };

    const updateSelectedGroups = (groups: OrganizationGroupInterface[]) => {
      selectedOrganizationGroups.value = groups;
    };

    const loadingDates: ComputedRef<Date[]> | undefined = inject(
      "loadingDates"
    );

    const organizationsIsInBlackListValidator = (
      organizations: OrganizationViewInterface[] | null
    ) => {
      return (
        organizations == null ||
        organizations.length === 0 ||
        !organizations.every(o => o.isInBlackList)
      );
    };

    const v$ = useVuelidate(
      {
        executorRoleType: {
          required
        },
        forwardedPrice: {
          requiredIf: requiredIf(
            () =>
              orderType.value === OrderTypeEnum.ToExecutor &&
              filteredForwardingOrganizations.value?.length == 1
          ),
          min(val: string) {
            const valueAsNumber = convertStringToNumber(val);

            return !helpers.req(val) || valueAsNumber >= 0.01
              ? {
                  $valid: true
                }
              : {
                  $valid: false,
                  message: "Стоимость должна быть выше 0"
                };
          }
        },
        forwardToOrganizations: {
          required: requiredIf(
            () => orderType.value === OrderTypeEnum.ToExecutor
          ),
          organizationsIsInBlackList: helpers.withMessage(
            "В списке выбранных организаций, есть организация из «Черного списка»",
            organizationsIsInBlackListValidator
          )
        },
        conditions: {
          defermentOfPaymentDays: {
            required: requiredIf(
              () =>
                orderType.value === OrderTypeEnum.ToHub ||
                (orderType.value === OrderTypeEnum.ToExecutor &&
                  selectedNotPartners.value.length > 0)
            )
          },
          documentsFormat: {
            required: requiredIf(
              () =>
                orderType.value === OrderTypeEnum.ToHub ||
                (orderType.value === OrderTypeEnum.ToExecutor &&
                  selectedNotPartners.value.length > 0)
            )
          },
          documentExchangeDaysAfterFreightUnloading: {
            required: requiredIf(
              () =>
                orderType.value === OrderTypeEnum.ToHub ||
                (orderType.value === OrderTypeEnum.ToExecutor &&
                  selectedNotPartners.value.length > 0)
            )
          }
        },
        auctionTime: {
          requiredIf: requiredIf(
            () =>
              orderType.value === OrderTypeEnum.ToExecutor &&
              order.value.isAuctionAutoComplete
          ),
          min: minLength(5)
        },
        auctionBidStep: {
          requiredIf: requiredIf(
            () =>
              orderType.value === OrderTypeEnum.ToHub ||
              (orderType.value === OrderTypeEnum.ToExecutor &&
                filteredForwardingOrganizations.value.length > 1)
          )
        },
        auctionExtendTime: {
          requiredIf: requiredIf(
            () =>
              filteredForwardingOrganizations.value != null &&
              filteredForwardingOrganizations.value.length > 1 &&
              order.value.isAuctionAutoExtend
          ),
          max(val: string) {
            if (
              !(
                orderType.value === OrderTypeEnum.ToExecutor &&
                filteredForwardingOrganizations.value &&
                filteredForwardingOrganizations.value.length > 1 &&
                order.value.isAuctionAutoExtend
              )
            )
              return true;

            if (val.length < 1) return false;

            const extendTime = convertStringToNumber(val);

            return extendTime > 0 && extendTime < 60;
          }
        },
        auctionExtendActivationTime: {
          requiredIf: requiredIf(
            () =>
              orderType.value === OrderTypeEnum.ToExecutor &&
              filteredForwardingOrganizations.value.length > 1 &&
              order.value.isAuctionAutoExtend
          ),
          max(val: string) {
            if (
              !(
                orderType.value === OrderTypeEnum.ToExecutor &&
                filteredForwardingOrganizations.value.length > 1 &&
                order.value.isAuctionAutoExtend
              )
            )
              return true;

            if (val.length < 1) return false;

            const activationTime = convertStringToNumber(val);

            return activationTime > 0 && activationTime < 60;
          }
        },
        extendTimeLimit: {
          requiredIf: requiredIf(
            () =>
              orderType.value === OrderTypeEnum.ToExecutor &&
              filteredForwardingOrganizations.value.length > 1 &&
              order.value.isAuctionAutoExtend
          ),
          max(val: string) {
            if (
              !(
                orderType.value === OrderTypeEnum.ToExecutor &&
                filteredForwardingOrganizations.value.length > 1 &&
                order.value.isAuctionAutoExtend
              )
            ) {
              return true;
            }
            if (
              val.length < 5 ||
              loadingDates === undefined ||
              loadingDates.value.length === 0
            ) {
              return false;
            }

            const timeArray = val.split(":");
            const hours = convertStringToNumber(timeArray[0] ?? 0);
            const minutes = convertStringToNumber(timeArray[1] ?? 0);

            const currentDate = new Date();
            const loadingDate =
              loadingDates?.value[0] ??
              add(currentDate, {
                hours: hours,
                minutes: minutes - convertStringToNumber(activationTime.value)
              });

            const extendedTimeMax = add(currentDate, {
              hours: hours,
              minutes: minutes
            });
            return (
              extendedTimeMax < loadingDate &&
              hours < 24 &&
              minutes < 60 &&
              hours + minutes !== 0
            );
          }
        }
      },
      order
    );

    return {
      genContractsUrl,
      filteredForwardingOrganizations,
      organizations,
      organizationGroups,
      allPartnersSelected,
      isInBlackListWarning,
      organizationsLoading,
      selectedOrganizationGroups,
      search,
      selectedNotPartners,
      selectedNotPartnersTitles,
      noDataMessage,
      selectedPartners,
      v$,
      selectAllPartners,
      selectOrderType,
      selectExecutorRoleType,
      getDefaultConditionValues,
      onSearchInput,
      onSelectFocus,
      onSelectBlur,
      onOrgSelect,
      onAutocompleteChange,
      onUpdateSelectedGroups,
      updateSelectedGroups,
      updateOrganizationGroups,
      onSelectGroup
    };
  }
});
