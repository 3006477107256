import useVuelidate from "@vuelidate/core";
import { computed, Ref } from "vue";
import { OrganizationTypeEnum } from "@/models/autocomplete/OrganizationType.enum";
import { personItemRules } from "@/validations/personItem/personItemRules";
import { PersonItemInterface } from "@/models/person/PersonItem.interface";

export function usePersonValidation(
  person: Ref<PersonItemInterface>,
  organizationType: OrganizationTypeEnum
) {
  const rules = computed(() => {
    return personItemRules(person, organizationType);
  });

  const v$ = useVuelidate(rules, person);

  return {
    v$
  };
}
