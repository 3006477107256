























































import InputMask from "@/components/UI/InputMask.vue";
import { ContactItemInterface } from "@/models/order/ContactItem.interface";
import { defineComponent, PropType } from "vue";
import CountrySelect from "@/components/order/city/CountrySelect.vue";

export default defineComponent({
  name: "ContactItem",
  components: {
    CountrySelect,
    InputMask
  },
  props: {
    contact: {
      type: Object as PropType<ContactItemInterface>,
      required: true
    },
    showRemove: {
      type: Boolean as PropType<boolean>,
      default: false
    },
    contactsPrompt: {
      type: Array as PropType<ContactItemInterface[]>,
      default: () => [] as ContactItemInterface[]
    }
  },
  emits: ["input", "remove"],
  computed: {
    phoneMask() {
      switch (this.contact.countryCode) {
        case "UZB":
          return "{+998} 00 000-0000";
        case "RUS":
        case "KAZ":
          return "{+7} 000 000-00-00";
        default:
          return null;
      }
    }
  },
  methods: {
    setContact(value: string | ContactItemInterface) {
      if (typeof value === "string") {
        this.contact.fullName = value;
        return;
      }
      this.contact.countryCode = value.countryCode;
      this.contact.fullName = value.fullName;
      this.contact.phone = value.phone;
    },
    changeCountry(country: string) {
      this.contact.countryCode = country;
      this.contact.phone = "";
    }
  }
});
