






















































































import BaseInput from "@/components/UI/BaseInput.vue";
import InputMask from "@/components/UI/InputMask.vue";
import { PersonType } from "@/models/person/Person.interface";
import fullNameMask from "@/mixins/fullNameMask";
import { OrganizationTypeEnum } from "@/models/autocomplete/OrganizationType.enum";
import { defineComponent, PropType, toRef } from "vue";
import { usePersonValidation } from "@/components/personItem/usePersonValidation";
import { useTaxTypes } from "@/components/personItem/useTaxTypes";
import { PersonItemInterface } from "@/models/person/PersonItem.interface";
import PersonService from "@/models/person/Person.service";
import { usePersonInnFieldViewConfig } from "@/components/personItem/usePersonInnFieldViewConfig";
import { OrganizationInterface } from "@/models/store/Organization.interface";
import CountrySelect from "@/components/order/city/CountrySelect.vue";

export default defineComponent({
  name: "PersonItemFtl",
  components: {
    CountrySelect,
    InputMask,
    BaseInput
  },
  props: {
    person: {
      type: Object as PropType<PersonItemInterface>,
      required: true
    },
    type: {
      type: [String, Number] as PropType<OrganizationTypeEnum>,
      default: OrganizationTypeEnum.Customer
    }
  },
  setup(props) {
    const _organizationType = toRef(props, "type");
    const _person = toRef(props, "person");

    const { v$ } = usePersonValidation(_person, _organizationType.value);
    const { taxesTypes, getTaxType } = useTaxTypes(_person);
    const { returnLabelForInn } = usePersonInnFieldViewConfig();

    const setInn = async (value: string | PersonItemInterface) => {
      if (typeof value === "string") {
        _person.value.inn = value;
        if (_person.value.countryCode == "RUS") {
          await PersonService.setPersonInn(_person.value, value);
        }
        return;
      }

      await PersonService.updatePerson(
        _person.value,
        value,
        _organizationType.value
      );
    };
    const changePersonType = (type: PersonType) => {
      PersonService.changePersonType(_person.value, type);
    };
    const changeCountry = (country: string) => {
      _person.value.countryCode = country;

      changePersonType(taxesTypes.value[0].value);
    };
    const orgFilter = (org: OrganizationInterface, query: string): boolean => {
      return (
        (!query?.trim() ||
          org.inn?.includes(query.toLowerCase().trim()) ||
          org.name?.toLowerCase().includes(query.toLowerCase().trim()) ||
          org.lastName?.toLowerCase().includes(query.toLowerCase().trim()) ||
          org.firstName?.toLowerCase().includes(query.toLowerCase().trim()) ||
          org.middleName?.toLowerCase().includes(query.toLowerCase().trim())) ??
        false
      );
    };

    return {
      v$,
      fullNameMask,
      taxesTypes,
      getTaxType,
      setInn,
      changePersonType,
      changeCountry,
      returnLabelForInn,
      orgFilter
    };
  },
  computed: {
    personListAutocomplete(): OrganizationInterface[] {
      let personListName: "customerList" | "senderList" | "recipientList";

      switch (this.type) {
        case OrganizationTypeEnum.Customer:
          personListName = "customerList";
          break;
        case OrganizationTypeEnum.Sender:
          personListName = "senderList";
          break;
        default:
          personListName = "recipientList";
      }

      const personList: OrganizationInterface[] = this.$store.getters[
        `autocomplete/${personListName}`
      ];

      return personList.filter(organization => {
        return organization.type === this.person.type;
      });
    }
  }
});
