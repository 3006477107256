var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',[_c('p',{staticClass:"text-h4"},[_vm._v("Дополнительные опции")]),_c('v-row',[_c('v-col',{attrs:{"cols":"12","lg":"3"}},[_c('v-select',{attrs:{"label":"Назначение","items":[
          {
            id: 0,
            title: 'К себе'
          },
          {
            id: 1,
            title: 'В хаб'
          },
          {
            id: 2,
            title: 'На исполнителя'
          }
        ],"item-text":"title","item-value":"id","outlined":"","dense":"","color":"secondary","item-color":"secondary"},on:{"input":function($event){return _vm.selectOrderType(_vm.order.orderType)}},model:{value:(_vm.order.orderType),callback:function ($$v) {_vm.$set(_vm.order, "orderType", $$v)},expression:"order.orderType"}})],1),(_vm.order.orderType === 1 || _vm.order.orderType === 2)?_c('v-col',{attrs:{"cols":"12","lg":"3"}},[_c('v-select',{attrs:{"label":"Роль исполнителя *","items":[
          {
            id: 0,
            title: 'Любая'
          },
          {
            id: 1,
            title: 'Экспедитор'
          },
          {
            id: 2,
            title: 'Перевозчик'
          }
        ],"item-text":"title","item-value":"id","outlined":"","dense":"","color":"secondary","item-color":"secondary"},on:{"input":function($event){return _vm.selectExecutorRoleType()}},model:{value:(_vm.order.executorRoleType),callback:function ($$v) {_vm.$set(_vm.order, "executorRoleType", $$v)},expression:"order.executorRoleType"}})],1):_vm._e(),(_vm.order.orderType === 1 || _vm.order.orderType === 2)?_c('v-col',{attrs:{"cols":"12","lg":"3"}},[_c('input-mask',{attrs:{"color":"secondary","label":"Стоимость для перевозчика","mask":Number,"small":"","thousands-separator":" ","min":0,"postfix":"₽","meta":_vm.v$.forwardedPrice},model:{value:(_vm.order.forwardedPrice),callback:function ($$v) {_vm.$set(_vm.order, "forwardedPrice", $$v)},expression:"order.forwardedPrice"}})],1):_vm._e()],1),_c('v-row',[(_vm.order.executorRoleType === 2 && _vm.order.orderType === 2)?_c('v-col',{attrs:{"cols":"12","lg":"12"}},[_c('organization-groups',{attrs:{"order":_vm.order,"organization-groups":_vm.organizationGroups,"selected-organization-groups":_vm.selectedOrganizationGroups,"on-select-group":_vm.onSelectGroup},on:{"update-organization-groups":_vm.updateOrganizationGroups,"update-selected-groups":_vm.updateSelectedGroups}})],1):_vm._e()],1),_c('v-row',[(_vm.order.orderType === 2)?_c('v-col',{attrs:{"cols":"12","lg":"12"}},[_c('v-combobox',{staticClass:"custom-combobox",attrs:{"value":_vm.order.forwardToOrganizations,"label":"Организация *","multiple":"","dense":"","outlined":"","no-filter":"","small-chips":"","item-value":"id","item-text":"title","color":"secondary","item-color":"secondary","search-input":_vm.search,"items":_vm.organizations,"loading":_vm.organizationsLoading,"error":_vm.v$.forwardToOrganizations.$error,"error-messages":_vm.v$.forwardToOrganizations.organizationsIsInBlackList.$invalid
            ? _vm.v$.forwardToOrganizations.organizationsIsInBlackList.$message
            : '',"messages":_vm.isInBlackListWarning},on:{"update:search-input":_vm.onSearchInput,"blur":_vm.onSelectBlur,"focus":_vm.onSelectFocus,"input":_vm.onOrgSelect},scopedSlots:_vm._u([{key:"selection",fn:function(ref){
            var attrs = ref.attrs;
            var item = ref.item;
            var parent = ref.parent;
            var selected = ref.selected;
return [(item === Object(item))?_c('v-chip',_vm._b({staticClass:"my-2 rounded-pill",attrs:{"input-value":selected,"small":"","label":""}},'v-chip',attrs,false),[_c('span',{staticClass:"pr-2"},[_vm._v(" "+_vm._s(item.title)+" ")]),_c('v-icon',{attrs:{"small":""},on:{"click":function($event){return parent.selectItem(item)}}},[_vm._v(" $delete ")])],1):_vm._e()]}},{key:"prepend-item",fn:function(){return [_c('v-list-item',{attrs:{"disabled":_vm.allPartnersSelected},on:{"click":_vm.selectAllPartners}},[_c('v-list-item-content',[_vm._v(" "+_vm._s(_vm.allPartnersSelected ? "Все партнёры выбраны" : "Выбрать всех партнёров")+" ")])],1),_c('v-divider',{staticClass:"mt-2"})]},proxy:true},{key:"item",fn:function(ref){
            var item = ref.item;
return [_c('v-list-item-content',[_c('v-row',{staticClass:"ma-0",attrs:{"align":"center"}},[_c('span',[_vm._v(_vm._s(item.title))]),(item.isVatPayer)?_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
            var on = ref.on;
            var attr = ref.attr;
return [_c('img',_vm._g(_vm._b({staticClass:"ml-1",attrs:{"src":"/svg/percent-circle.svg","alt":"percent"}},'img',attr,false),on))]}}],null,true)},[_c('span',[_vm._v("ОСНО - организация является плательщиком ндс")])]):_vm._e()],1),_c('p',{staticClass:"caption grey--text mb-0"},[(!!item.genContractId)?[_c('v-icon',{staticStyle:{"vertical-align":"baseline"},attrs:{"x-small":""}},[_vm._v(" mdi-check-circle-outline ")]),_vm._v(" "+_vm._s(_vm.order.executorRoleType !== 0 ? "Партнёр" : item.partnerExecutorRoleType === 0 ? "Партнёр-гибрид" : item.partnerExecutorRoleType === 1 ? "Партнёр-экспедитор + перевозчик" : "Партнёр-перевозчик + экспедитор")+" • ")]:_vm._e(),_vm._v(" Корпорация: "+_vm._s(item.corporation.title)),_c('br'),_vm._v(" ИНН: "+_vm._s(item.inn)+" ")],2)],1)]}},{key:"no-data",fn:function(){return [_c('v-list-item',[_c('v-list-item-content',[_c('v-list-item-title',[_vm._v(" "+_vm._s(_vm.noDataMessage)+" ")])],1)],1)]},proxy:true}],null,false,3782403799)},[_c('v-chip',{staticClass:"my-2 rounded-pill",attrs:{"small":""}},[_c('v-icon',{attrs:{"left":""}},[_vm._v(" mdi-plus ")]),_vm._v(" Добавить ")],1)],1)],1):_vm._e()],1),(
      _vm.order.orderType === 2 &&
        _vm.filteredForwardingOrganizations &&
        _vm.filteredForwardingOrganizations.length === 1
    )?_c('v-row',[_c('v-col',{attrs:{"cols":"12","lg":"3"}},[_c('v-checkbox',{staticStyle:{"margin":"-6px 0 8px 0"},attrs:{"color":"secondary","label":"Возможен торг"},model:{value:(_vm.order.isAuction),callback:function ($$v) {_vm.$set(_vm.order, "isAuction", $$v)},expression:"order.isAuction"}})],1),(_vm.order.isAuction)?_c('v-col',{attrs:{"cols":"12","lg":"6"}},[_c('input-mask',{staticStyle:{"margin-top":"-10px"},attrs:{"mask":Number,"min":0,"label":"Кратность стоимости перевозки","postfix":"₽","small":"","thousands-separator":" "},model:{value:(_vm.order.auctionBidStep),callback:function ($$v) {_vm.$set(_vm.order, "auctionBidStep", $$v)},expression:"order.auctionBidStep"}})],1):_vm._e()],1):_vm._e(),(
      _vm.order.orderType === 1 ||
        (_vm.order.orderType === 2 && _vm.selectedNotPartners.length > 0)
    )?[_c('p',{staticClass:"text-h4"},[_vm._v("Условия договора")]),(_vm.order.orderType === 2 && _vm.selectedNotPartners.length > 0)?_c('p',[_vm._v(" Для компаний без утвержденных условий перевозки: "+_vm._s(_vm.selectedNotPartnersTitles)+" ")]):_vm._e(),_c('v-row',[_c('v-col',{attrs:{"cols":"12","lg":"4"}},[_c('InputMask',{attrs:{"label":"Отсрочка предоставления закрывающих документов *","small":"","mask":Number,"thousands-separator":" ","min":0,"postfix":"дн","meta":_vm.v$.conditions.documentExchangeDaysAfterFreightUnloading},model:{value:(_vm.order.conditions.documentExchangeDaysAfterFreightUnloading),callback:function ($$v) {_vm.$set(_vm.order.conditions, "documentExchangeDaysAfterFreightUnloading", $$v)},expression:"order.conditions.documentExchangeDaysAfterFreightUnloading"}})],1),_c('v-col',{attrs:{"cols":"12","lg":"4"}},[_c('InputMask',{attrs:{"label":"Количество дней отсрочки после передачи документов *","small":"","mask":Number,"thousands-separator":" ","min":0,"postfix":"дн","meta":_vm.v$.conditions.defermentOfPaymentDays},model:{value:(_vm.order.conditions.defermentOfPaymentDays),callback:function ($$v) {_vm.$set(_vm.order.conditions, "defermentOfPaymentDays", $$v)},expression:"order.conditions.defermentOfPaymentDays"}})],1),_c('v-col',{attrs:{"cols":"12","lg":"4"}},[_c('v-select',{attrs:{"label":"Формат документов *","items":[
            { text: 'Оригинал', value: 'Оригинал' },
            { text: 'Копия', value: 'Копия' },
            { text: 'Заверенная копия', value: 'Заверенная копия' }
          ],"dense":"","outlined":"","color":"secondary","item-color":"secondary"},model:{value:(_vm.order.conditions.documentsFormat),callback:function ($$v) {_vm.$set(_vm.order.conditions, "documentsFormat", $$v)},expression:"order.conditions.documentsFormat"}})],1)],1)]:_vm._e(),_c('v-row',[_c('v-col',{attrs:{"cols":"12","lg":"12"}},[_c('VTextarea',{attrs:{"label":"Особые условия","rows":"3","auto-grow":"","outlined":"","color":"secondary"},model:{value:(_vm.order.conditions.otherConditions),callback:function ($$v) {_vm.$set(_vm.order.conditions, "otherConditions", $$v)},expression:"order.conditions.otherConditions"}})],1)],1),(_vm.order.orderType === 2 && _vm.selectedPartners.length > 0)?_c('p',[_vm._v(" Для "+_vm._s(_vm.selectedNotPartners.length > 0 ? "остальных " : "")+"компаний используются "),_c('a',{staticStyle:{"color":"#0668BE"},attrs:{"href":"https://tdsd.pro/GenContracts","target":"_blank"}},[_vm._v("утвержденные сторонами условия перевозки")])]):_vm._e(),(
      _vm.order.orderType === 1 ||
        (_vm.order.orderType === 2 &&
          _vm.filteredForwardingOrganizations &&
          _vm.filteredForwardingOrganizations.length > 1)
    )?[_c('p',{staticClass:"text-h4"},[_vm._v("Торги")]),_c('v-row',[(
          _vm.order.orderType === 1 ||
            (_vm.filteredForwardingOrganizations &&
              _vm.filteredForwardingOrganizations.length > 1)
        )?_c('v-col',{attrs:{"cols":"12","lg":"4"}},[_c('input-mask',{attrs:{"label":'Длительность торгов, чч:мм' + (_vm.order.orderType === 2 ? ' *' : ''),"mask":"00{:}00","small":"","meta":_vm.v$.auctionTime},model:{value:(_vm.order.auctionTime),callback:function ($$v) {_vm.$set(_vm.order, "auctionTime", $$v)},expression:"order.auctionTime"}})],1):_vm._e(),_c('v-col',{attrs:{"cols":"12","lg":"4"}},[_c('InputMask',{attrs:{"label":"Шаг торгов *","small":"","mask":Number,"thousands-separator":" ","min":0,"postfix":"₽","meta":_vm.v$.auctionBidStep},model:{value:(_vm.order.auctionBidStep),callback:function ($$v) {_vm.$set(_vm.order, "auctionBidStep", $$v)},expression:"order.auctionBidStep"}})],1),(
          _vm.filteredForwardingOrganizations &&
            _vm.filteredForwardingOrganizations.length > 1
        )?_c('v-col',{attrs:{"cols":"2","lg":"2"}},[_c('v-checkbox',{staticStyle:{"margin-top":"1px","float":"left"},attrs:{"color":"secondary","label":"Автовыбор"},on:{"change":_vm.onAutocompleteChange},model:{value:(_vm.order.isAuctionAutoComplete),callback:function ($$v) {_vm.$set(_vm.order, "isAuctionAutoComplete", $$v)},expression:"order.isAuctionAutoComplete"}})],1):_vm._e()],1),_c('v-row',[(
          (_vm.order.orderType === 1 ||
            (_vm.filteredForwardingOrganizations &&
              _vm.filteredForwardingOrganizations.length > 1)) &&
            +_vm.order.forwardedPrice > 0
        )?_c('v-col',{attrs:{"cols":"6","xl":"2","lg":"3","md":"3","sm":"4"}},[_c('v-checkbox',{staticStyle:{"margin-top":"1px","float":"left"},attrs:{"color":"secondary","label":"Только на понижение"},model:{value:(_vm.order.isPriceOnlyDescend),callback:function ($$v) {_vm.$set(_vm.order, "isPriceOnlyDescend", $$v)},expression:"order.isPriceOnlyDescend"}})],1):_vm._e(),(
          _vm.filteredForwardingOrganizations &&
            _vm.filteredForwardingOrganizations.length > 1 &&
            _vm.order.isAuctionAutoComplete
        )?_c('v-col',{attrs:{"cols":"3","xl":"1","md":"2"}},[_c('v-checkbox',{staticStyle:{"margin-top":"1px","float":"left"},attrs:{"color":"secondary","label":"Автопродление"},model:{value:(_vm.order.isAuctionAutoExtend),callback:function ($$v) {_vm.$set(_vm.order, "isAuctionAutoExtend", $$v)},expression:"order.isAuctionAutoExtend"}})],1):_vm._e()],1),_c('v-row',[(
          _vm.filteredForwardingOrganizations &&
            _vm.filteredForwardingOrganizations.length > 1 &&
            _vm.order.isAuctionAutoComplete &&
            _vm.order.isAuctionAutoExtend
        )?_c('v-col',{attrs:{"cols":"12","lg":"4"}},[_c('input-mask',{attrs:{"label":'Время активации автопродления, мин *',"mask":Number,"max":59,"min":0,"small":"","meta":_vm.v$.auctionExtendActivationTime},model:{value:(_vm.order.auctionExtendActivationTime),callback:function ($$v) {_vm.$set(_vm.order, "auctionExtendActivationTime", $$v)},expression:"order.auctionExtendActivationTime"}}),_c('small',{staticStyle:{"color":"#888"}},[_vm._v("Максимальное значение 59 мин.")])],1):_vm._e(),(
          _vm.filteredForwardingOrganizations &&
            _vm.filteredForwardingOrganizations.length > 1 &&
            _vm.order.isAuctionAutoComplete &&
            _vm.order.isAuctionAutoExtend
        )?_c('v-col',{attrs:{"cols":"12","lg":"4"}},[_c('input-mask',{attrs:{"label":'Время продления торгов, мин *',"mask":"00","small":"","meta":_vm.v$.auctionExtendTime},model:{value:(_vm.order.auctionExtendTime),callback:function ($$v) {_vm.$set(_vm.order, "auctionExtendTime", $$v)},expression:"order.auctionExtendTime"}}),_c('small',{staticStyle:{"color":"#888"}},[_vm._v("Максимальное значение 59 мин.")])],1):_vm._e(),(
          _vm.filteredForwardingOrganizations &&
            _vm.filteredForwardingOrganizations.length > 1 &&
            _vm.order.isAuctionAutoComplete &&
            _vm.order.isAuctionAutoExtend
        )?_c('v-col',{attrs:{"cols":"12","lg":"4"}},[_c('input-mask',{attrs:{"label":'Максимальная длительность автопродления, чч:мм *',"mask":"00{:}00","small":"","meta":_vm.v$.extendTimeLimit},model:{value:(_vm.order.extendTimeLimit),callback:function ($$v) {_vm.$set(_vm.order, "extendTimeLimit", $$v)},expression:"order.extendTimeLimit"}}),_c('small',{staticStyle:{"color":"#888"}},[_vm._v("Максимальное значение 23:59 мин., но не позднее времени погрузки")])],1):_vm._e()],1)]:_vm._e()],2)}
var staticRenderFns = []

export { render, staticRenderFns }