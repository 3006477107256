import { helpers, required, requiredIf } from "@vuelidate/validators";
import { OrganizationTypeEnum } from "@/models/autocomplete/OrganizationType.enum";
import { PersonType } from "@/models/person/Person.interface";
import { Ref } from "vue";
import { PersonItemInterface } from "@/models/person/PersonItem.interface";
import { twoWordsInName } from "@/validations/person";

export function personItemRules(
  person: Ref<PersonItemInterface>,
  organizationType: OrganizationTypeEnum
) {
  return {
    id: {},
    countryCode: {},
    clearData: {},
    getFullName: {},
    updateData: {},
    type: {
      required
    },
    fullName: {
      required: requiredIf(function() {
        return (
          organizationType !== OrganizationTypeEnum.Customer &&
          (person.value.type === PersonType.INDIVIDUAL ||
            person.value.type === PersonType.ENTREPRENEUR)
        );
      }),
      twoWordsInName
    },
    firstName: {
      required: requiredIf(function() {
        return (
          organizationType === OrganizationTypeEnum.Customer &&
          (person.value.type === PersonType.INDIVIDUAL ||
            person.value.type === PersonType.ENTREPRENEUR)
        );
      })
    },
    middleName: {
      required: requiredIf(function() {
        return (
          organizationType === OrganizationTypeEnum.Customer &&
          (person.value.type === PersonType.INDIVIDUAL ||
            person.value.type === PersonType.ENTREPRENEUR)
        );
      })
    },
    lastName: {
      required: requiredIf(function() {
        return (
          organizationType === OrganizationTypeEnum.Customer &&
          (person.value.type === PersonType.INDIVIDUAL ||
            person.value.type === PersonType.ENTREPRENEUR)
        );
      })
    },
    name: {
      required: requiredIf(function() {
        return person.value.type === PersonType.COMPANY;
      })
    },
    inn: {
      min: (value: unknown): boolean => {
        const strValue = value as string;
        if (
          (person.value.type !== PersonType.COMPANY &&
            person.value.type !== PersonType.ENTREPRENEUR) ||
          !helpers.req(value)
        ) {
          return true;
        }

        if (person.value.type === PersonType.COMPANY) {
          switch (person.value.countryCode) {
            case "RUS":
              return strValue.length === 10;
            case "KAZ":
              return strValue.length === 12;
            case "UZB":
              return strValue.length === 9;
            default:
              return true;
          }
        }

        switch (person.value.countryCode) {
          case "RUS":
          case "KAZ":
            return strValue.length === 12;
          case "UZB":
            return strValue.length === 14;
          default:
            return true;
        }
      },
      required: requiredIf(function() {
        return (
          person.value.type === PersonType.COMPANY ||
          person.value.type === PersonType.ENTREPRENEUR
        );
      })
    },
    address: {}
  };
}
