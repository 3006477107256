



































import BaseInput from "@/components/UI/BaseInput.vue";
import { startOfDay } from "date-fns";
import getLocaleDateString from "@/utils/getLocaleDateString";
import { defineComponent, PropType } from "vue";
import { Validation } from "@vuelidate/core";

export default defineComponent({
  name: "DatePicker",
  components: { BaseInput },
  props: {
    label: {
      type: String as PropType<string>,
      default: ""
    },
    value: {
      type: String as PropType<string>,
      required: true
    },
    meta: {
      type: Object as PropType<Validation>,
      default: () => ({} as Validation)
    },
    error: {
      type: [String, Array] as PropType<string | string[]>,
      default: ""
    },
    min: {
      type: String
    },
    max: {
      type: String
    }
  },
  data() {
    return {
      showDatepicker: false
    };
  },
  computed: {
    localeDateString(): string {
      if (!this.value) return "";

      return getLocaleDateString(this.value);
    }
  },
  methods: {
    onBlur(): void {
      if (!this.meta) return;

      this.meta.$touch();
    },
    showDatePicker(): void {
      this.showDatepicker = true;
    }
  }
});
