import { infotekaApi as api } from "@/api/baseApi";
import { ListItemInterface } from "@/models/global/ListItem.interface";
import { AxiosResponse } from "axios";
import { CountryInterface } from "@/models/global/CountryInterface";

export async function getCargoCategories(): Promise<ListItemInterface[]> {
  const res: AxiosResponse<ListItemInterface[]> = await api.post(
    "/ProductCategories"
  );

  return res.data;
}

export async function getBodyTypes(): Promise<ListItemInterface[]> {
  const res: AxiosResponse<ListItemInterface[]> = await api.post(
    "/CarBodyTypes"
  );

  return res.data;
}

export async function getLoadingType(): Promise<ListItemInterface[]> {
  const res: AxiosResponse<ListItemInterface[]> = await api.post(
    "/UnloadingTypes"
  );

  return res.data;
}

export async function getVatRateTypes(): Promise<ListItemInterface[]> {
  const res: AxiosResponse<ListItemInterface[]> = await api.post(
    "/VatRatesTypes"
  );

  return res.data;
}

export async function getCountries(): Promise<CountryInterface[]> {
  const res: AxiosResponse<CountryInterface[]> = await api.post(
    "/CountryCodes"
  );
  return res.data;
}
